/// <reference path="./common_controllers.ts" />
/// <reference path="../services/generic.services.ts" />
angular.module('webApp').directive('isinteger', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            allownull: '='
        },
        link: function (scope:any, element, attrs, ngModel:any, allownull) {
            if(attrs && allownull) {}
            var check = function (value) {
                if (!value) {
                    if (scope.allownull) {
                        element.css("background-color", "white");
                    }
                    else {
                        element.css("background-color", "red");
                    }
                    return value;
                }
                if (isInt(value)) {
                    element.css("background-color", "white");
                }
                else {
                    element.css("background-color", "#ffcccc");
                }
                return value;
            };
            ngModel.$formatters.push(check);
            ngModel.$parsers.push(check);
        }
    };
});
angular.module('webApp').directive('isfloat', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            allownull: '='
        },
        link: function (scope:any, element, attrs, ngModel:any, allownull) {
            if(attrs && allownull) {}
            var check = function (value) {
                if (!value) {
                    if (scope.allownull) {
                        element.css("background-color", "white");
                    }
                    else {
                        element.css("background-color", "red");
                    }
                    return value;
                }
                if (isFloat(value) || isInt(value)) {
                    element.css("background-color", "white");
                }
                else {
                    element.css("background-color", "#ffcccc");
                }
                return value;
            };
            ngModel.$formatters.push(check);
            ngModel.$parsers.push(check);
        }
    };
});

// angular.module('webApp').directive('checkList', function () {
//     return {
//         scope: {
//             list: '=checkList',
//             list2: '=list',
//             value: '@'
//         },
//         link: function (scope:any, elem, attrs) {
//             if(attrs){}
//             var handler = function (setup) {
//                 if (!scope.list) {
//                     return;
//                 }
//                 var checked = elem.prop('checked');
//                 var index = scope.list.indexOf(scope.value);
//                 if (checked && index == -1) {
//                     if (setup)
//                         elem.prop('checked', false);
//                     else
//                         scope.list.push(scope.value);
//                 }
//                 else if (!checked && index != -1) {
//                     if (setup)
//                         elem.prop('checked', true);
//                     else
//                         scope.list.splice(index, 1);
//                 }
//             };
//             var setupHandler = handler.bind(null, true);
//             var changeHandler = handler.bind(null, false);
//             elem.bind('change', function () {
//                 scope.$apply(changeHandler);
//             });
//             scope.$watch('list', setupHandler, true);
//         }
//     };
// });
angular.module('webApp').directive("whenScrolled", function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            // we get a list of elements of size 1 and need the first element
            //var raw = elem[0];
            //var raw = elem[0];
            if(elem) {}
            //var raw = $(window);
            // var raw = angular.element(window);
            // var doc = angular.element(document);
            // we load more elements when scrolled past a limit
            // raw.on("scroll", function () {
            //     //if(raw.scrollTop+raw.offsetHeight+20 >= raw.scrollHeight){
            //     //if ($(window).scrollTop() >= ($(document).height() - $(window).height()) * 0.5) {
            //     if (document.body.scrollTop >= (doc[0].offsetHeight - raw[0].offsetHeight) * 0.5) {
            //         // we can give any function which loads more elements into the list
            //         scope.$apply(attrs.whenScrolled);
            //     }
            // });
        }
    };
});

class zenchecklist implements ng.IDirective {
    constructor() {}
    public restrict = 'A';
    // public controller: string = 'zenFormElementCtrl';
    // public controllerAs: string = 'vm';
    // public replace: boolean = true;
    scope = {
        list: '=zenchecklist',
        value: '@'
    };
    link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) {
        var handler = function (setup) {
            if(!Array.isArray(scope.list)) {
                scope.list = [];
            }
            var checked = element.prop('checked');
            var index = scope.list.indexOf(scope.value);
            if (checked && index == -1) {
                if (setup) element.prop('checked', false);
                else scope.list.push(scope.value);
            } else if (!checked && index != -1) {
                if (setup) element.prop('checked', true);
                else scope.list.splice(index, 1);
            }
        };

        var setupHandler = handler.bind(null, true);
        var changeHandler = handler.bind(null, false);

        element.bind('change', function () {
            scope.$apply(changeHandler);
        });
        scope.$watch('list', setupHandler, true);
    }
}
angular.module('webApp').directive('zenchecklist', [() => new zenchecklist()]);

class localizationlist implements ng.IDirective {
    genericServices: GenericServiceClass;
    
    public template:string = '<select ng-model="selectedValue" class="form-control" ' +
    ' ng-options="item.id as item.name for item in options" ' +
    '></select>';
    //public replace: boolean = true;
    scope = {
        selectedValue: '='
    };
    link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) {
        scope.$watch('selectedValue', (newValue, oldValue, scope)=>{
            if(newValue=='') {
                scope.selectedValue = null;
            }
            
        }, true);
    }
    controller = ['$scope', 'socketService', 'locale', function (scope, socketService:socketServiceFactory, locale) {
        scope.options = [];
        scope.loaddata = async function() {
            try {
                var query = "_type eq 'localization' and locale eq 'en-US'";
                var items = await socketService.generic.get(query, 1, 0, null, null, 'localization');
                if (items.length == 0) {
                    console.error('No such locale found en-US');
                    return;
                }
                
                var common = items[0].common;
                var keys = Object.keys(common);
                scope.options.push({
                    id: '',
                    name: ''
                });
                keys.forEach(key => {
                    scope.options.push({
                        id: 'common.' + key,
                        name: common[key]
                    });
                });
                if (!scope.$$phase) { scope.$apply(); }
            } catch (err) {
                console.error(err)
            }
        }
        socketService.onSignedin(() => {
            scope.loaddata();
        });
    }];
}
angular.module('webApp').directive('localizationlist', [() => new localizationlist()]);

class focusdirective implements ng.IDirective {
    static instance($timeout) {
        return new focusdirective($timeout)
    }
    constructor(
        public $timeout
    ) {}
    public restrict = 'A';
    scope = {
        focus: '='
    };
    link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) {
        attrs.$observe('disabled', (value)=>{
            if (value === "false" || value === false) {
                if(scope.focus=="true" || scope.focus==true) {
                    this.$timeout(function () {
                        element[0].focus();
                    }, 50);
                }
            }
        });
        scope.$watch('focus', (newValue, oldValue, scope)=>{
            if (newValue === "true" || newValue === true) {
                this.$timeout(function () {
                    element[0].focus();
                }, 50);
            }
        });

    }
}
angular.module('webApp').directive('focus', ['$timeout', focusdirective.instance]);

class focusinjectordirective implements ng.IDirective {
    static instance($timeout, $compile) {
        return new focusinjectordirective($timeout, $compile)
    }
    constructor(
        public $timeout,
        public $compile
    ) {}
    //public restrict = 'E';
    scope = {
        focuson: '='
    };
    link(scope: any, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) {
        scope.$watch('focuson', (newValue, oldValue, scope)=>{
            
            if(newValue==undefined || newValue=='') { return }
            // var element = angular.element(document.querySelector('#' + newValue));
           
            // if(element.length == 1) {
            //     this.$timeout(function () { 
            //         element[0].focus();
            //     }, 50);
            // }
        });
    }
}
angular.module('webApp').directive('focusinjector', ['$timeout', '$compile', focusinjectordirective.instance]);